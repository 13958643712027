<template>
  <page-content show-search non-card-content>
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        :showBtn="false"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <b-row>
        <b-col v-for="task in tasks" cols="4" sm="3" md="2">
          <b-card>
            <template #header>
              <h4 class="mb-0">{{ task.title }}</h4>
              <feather-icon
                icon="ListIcon"
                size="20"
                v-b-tooltip.hover="$t('kiosk.task_details')"
                @click="handleDetailClick(task.taskType)"
                style="cursor: pointer; color: #76e"
              />
            </template>
            <img
              :src="task.icon"
              :alt="task.title" width="100%"
              @click="handleTaskTypeClick(task.taskType)"
              style="cursor: pointer;"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        ref="modalTask"
        :title="$t('kiosk.tasks')"
        :ok-title="$t('common.confirm')"
        :cancel-title="$t('common.cancel')"
        @show="handleModalShow"
        @ok="handleModalOk"
        @hidden="handleModalHidden"
        no-close-on-backdrop
      >
        <component
          v-if="currentTask"
          :is="'nw-form-' + currentTask.taskType"
          v-model="currentTask.formData"
          :select-options="tasksSelectOptions"
          ref="nwFormComp"
        />
      </b-modal>
    </template>
  </page-content>
</template>

<script>
import PageContent from "@/components/PageContent.vue";
import TableSearch from "@/components/TableSearch.vue";
import common from "@/common";
import kskNursingWorkMixin from "@/views/kiosk/mixins/kskNursingWork/kskNursingWorkMixin";

export default {
  name: "kioskNursingWork",
  mixins: [kskNursingWorkMixin],
  components: {
    TableSearch,
    PageContent,
  },
  data() {
    return {
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'memberCode',
          label: 'common.member',
          type: 'select',
          options: [],
          searchable: true
        },
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        memberCode: common.getCustomConfigByKey('memberCode') ?? null,
      },
    }
  },
  activated() {
    if (!common.isAdmin()) {
      common.getSearchMemberOptions('memberCode', common.getRole().companyID, common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
      common.getSearchMemberOptions('memberCode', this.filterValue.companyID, this.filterValue.facilityID, this)
    }
  },
  methods: {
    // Filter
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    // Modal
    handleTaskTypeClick(taskType) {
      if (!this.filterValue.memberCode) {
        common.showToast({
          title: this.$t("kiosk.pls_select_member"),
          variant: "warning",
        })
        return
      }

      this.currentTaskType = taskType
      this.$refs.modalTask.show()
    },

    // Detail
    handleDetailClick(taskType) {
      this.$router.push({
        name: 'kioskNursingWorkLog',
        query: {
          companyID: this.filterValue.companyID,
          facilityID: this.filterValue.facilityID,
          memberCode: this.filterValue.memberCode,
          taskType,
        }
      })
    },

    // refCode 生成的随机数, method from WeHealth 2.0 App
    generateRandomString() {
      const length = 6;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
  },
}
</script>

<style scoped>

</style>